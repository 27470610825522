
window._ = require('lodash');
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
require('bootstrap');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.Vue = require('vue');
let moment = require('moment-timezone');
window.momentRange = require('moment-range');
window.momentRange.extendMoment(moment);
window.moment = moment;
require('../utils.js');
