
window._ = require('lodash');
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
require('bootstrap');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.Vue = require('vue');
let moment = require('moment-timezone');
window.momentRange = require('moment-range');
window.momentRange.extendMoment(moment);
window.moment = moment;


window.Sentry = require('@sentry/vue');

//console.log('Sentry', window.Sentry);

window.Sentry.init({
    //debug: true,
    Vue: window.Vue,
    dsn: 'https://b76207c142bae9dad3793275d912e44c@o4508705743634432.ingest.us.sentry.io/4508732398239744',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",
        /^http:\/\/civana10\.test/,
        /^https:\/\/is.civanacarefree\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


require('../utils.js');
